exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-200-million-downloads-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/200-million-downloads/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-200-million-downloads-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-a-simple-guide-to-notarizing-your-java-application-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/a-simple-guide-to-notarizing-your-java-application/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-a-simple-guide-to-notarizing-your-java-application-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adding-third-party-application-tests-adoptopenjdk-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adding-third-party-application-tests-adoptopenjdk/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adding-third-party-application-tests-adoptopenjdk-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adding-zos-jenkins-build-farm-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adding-zos-jenkins-build-farm/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adding-zos-jenkins-build-farm-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adopt-openjdk-docker-images-now-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adopt-openjdk-docker-images-now-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adopt-openjdk-docker-images-now-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-11-new-default-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-11-new-default/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-11-new-default-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-15-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-15-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-15-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-16-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-16-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-16-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-252-1107-and-1401-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-8u252-1107-and-1401-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-252-1107-and-1401-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-262-1108-and-1402-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-8u262-1108-and-1402-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-262-1108-and-1402-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-265-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-8u265-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-265-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-272-1109-and-1501-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-8u272-1109-and-1501-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-272-1109-and-1501-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-275-and-11091-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-8u275-and-11091-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-275-and-11091-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-282-11010-and-1502-available-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-8u282-11010-and-1502-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-8-u-282-11010-and-1502-available-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-dns-redirect-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-dns-redirect/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-dns-redirect-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-to-join-the-eclipse-foundation-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-to-join-the-eclipse-foundation/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-to-join-the-eclipse-foundation-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-virtual-roadshow-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-virtual-roadshow/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-virtual-roadshow-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-welcomes-dragonwell-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/adoptopenjdk-welcomes-dragonwell/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-adoptopenjdk-welcomes-dragonwell-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aix-is-here-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/aix-is-here/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aix-is-here-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-bundling-adoptopenjdk-into-a-notarized-macos-application-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/bundling-adoptopenjdk-into-a-notarized-macos-application/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-bundling-adoptopenjdk-into-a-notarized-macos-application-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-contributing-adoptopenjdk-infrastructure-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/contributing-adoptopenjdk-infrastructure/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-contributing-adoptopenjdk-infrastructure-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-creating-an-open-source-build-farm-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/creating-an-open-source-build-farm/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-creating-an-open-source-build-farm-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-eclipse-adoptium-projects-ready-for-community-review-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/eclipse-adoptium-projects-ready-for-community-review/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-eclipse-adoptium-projects-ready-for-community-review-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-github-pages-cloudflare-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/github-pages-cloudflare/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-github-pages-cloudflare-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-goodbye-adoptopenjdk-hello-adoptium-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/goodbye-adoptopenjdk-hello-adoptium/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-goodbye-adoptopenjdk-hello-adoptium-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-build-openjdk-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/how-to-build-openjdk/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-build-openjdk-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-java-is-still-free-and-adoptopenjdk-adds-to-your-choices-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/java-is-still-free-and-adoptopenjdk-adds-to-your-choices/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-java-is-still-free-and-adoptopenjdk-adds-to-your-choices-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-jck-certification-and-an-anniversary-of-sorts-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/jck-certification-and-an-anniversary-of-sorts/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-jck-certification-and-an-anniversary-of-sorts-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-jumpstarting-open-performance-testing-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/jumpstarting-open-performance-testing/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-jumpstarting-open-performance-testing-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-macos-binary-changes-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/macos-binary-changes/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-macos-binary-changes-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-move-over-java-web-start-raise-your-glass-to-icedtea-web-v-181-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/move-over-java-web-start-raise-your-glass-to-icedtea-web-v181/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-move-over-java-web-start-raise-your-glass-to-icedtea-web-v-181-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-new-contribution-test-cases-adoptopenjdk-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/new-contribution-test-cases-adoptopenjdk/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-new-contribution-test-cases-adoptopenjdk-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-new-relic-joins-adoptopenjdk-project-to-strengthen-adoption-of-java-technology-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/new-relic-joins-adoptopenjdk-project-to-strengthen-adoption-of-java-technology/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-new-relic-joins-adoptopenjdk-project-to-strengthen-adoption-of-java-technology-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-off-great-start-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/off-great-start/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-off-great-start-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-openj-9-builds-available-here-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/openj9-builds-available-here/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-openj-9-builds-available-here-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-playing-test-microservices-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/playing-test-microservices/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-playing-test-microservices-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prerequisites-for-font-support-in-adoptopenjdk-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/prerequisites-for-font-support-in-adoptopenjdk/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prerequisites-for-font-support-in-adoptopenjdk-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-reaping-the-benefits-of-openness-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/reaping-the-benefits-of-openness/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-reaping-the-benefits-of-openness-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-sponsorship-at-adoptopenjdk-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/sponsorship-at-adoptopenjdk/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-sponsorship-at-adoptopenjdk-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-ssh-key-management-keybox-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/ssh-key-management-keybox/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-ssh-key-management-keybox-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-test-result-summary-service-trss-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/test-result-summary-service-trss/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-test-result-summary-service-trss-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-testing-java-help-count-ways-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/testing-java-help-count-ways/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-testing-java-help-count-ways-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-testing-java-let-count-ways-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/testing-java-let-count-ways/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-testing-java-let-count-ways-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-first-drop-introducing-adoptopenjdk-quality-assurance-aqa-v-1-0-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/the-first-drop-introducing-adoptopenjdk-quality-assurance-aqa-v1-0/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-first-drop-introducing-adoptopenjdk-quality-assurance-aqa-v-1-0-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-transition-to-eclipse-an-update-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/transition-to-eclipse-an-update/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-transition-to-eclipse-an-update-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-using-icedtea-web-browser-plug-in-with-adoptopenjdk-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/using-icedtea-web-browser-plug-in-with-adoptopenjdk/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-using-icedtea-web-browser-plug-in-with-adoptopenjdk-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-whats-up-at-adoptopenjdk-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/whats-up-at-adoptopenjdk/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-whats-up-at-adoptopenjdk-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-why-github-releases-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/home/runner/work/blog/blog/content/blog/why-github-releases/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-why-github-releases-index-md" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

